<template>
  <div>
    <apexchart ref="chart"  :width="chartWidth" :height="chartHeight" :options="chartOptions" :series="fromSeries"></apexchart>
  </div>

</template>
<script>

import moment from "moment";

function getChartOptions(chartTitle,dayCount,dayFromTo,ymin,ymax,xTitle,yTitle,series) {
  let xaxisAmount = dayCount == 1 ? 24 :  dayCount;

   // if(ymin<0){
   //   series.reduce(function (p, v) {
   //     return ( p < v ? p : v );
   //   });
   // }

  return {

    chart: {
      animations:{
        enabled:false
      },
      type: 'line',
        stacked: false,
        // height: 350,
      zoom: {
        type: 'x',enabled: true,autoScaleYaxis: true,
          animations:{
            enabled:false
          },
      },
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,// pan: true,// reset: true,// customIcons: []
        }
      },
    },

    dataLabels: {enabled: false},
    // markers: {size: 4},
    animations: {enabled: false,},
    stroke: {
      show: true,
          curve: 'smooth',
          // lineCap: 'butt',
          // colors: undefined,
          width: 0.7,
      // dashArray: 0,
    },
    title: {text: chartTitle,align: 'center',offsetX: 0,offsetY: 5,floating: true,style: {  fontSize:  '17px',  fontWeight:  'bold',  /* fontFamily:  undefined,*/  color:  '#263238'}},
    fill: {// type: 'gradient',// gradient: {//   shadeIntensity: 1,//   inverseColors: false,//   // opacityFrom: 0,//   // opacityTo: 0,//   // stops: [0, 90, 100]//
    },
    yaxis: {
      title:{text:yTitle,offsetX: 8},
      tickAmount: 5,
      min: ymin,
      max: ymax,
      labels: {
      formatter: function (val) {
        return val;
      },
    },
    tooltip: {
      enabled: false,
          formatter: function(val, opts) {
        return moment(val).format("H:mm")
      }
    },
    },
    xaxis: {
      title:{text:xTitle},
      type: 'datetime',
      tickAmount: xaxisAmount,
      min: new Date(dayFromTo.from).getTime(),
      max: new Date(dayFromTo.to).getTime(),
      labels: {
        formatter: function (val) {
          if (dayCount == 1) {
            return moment(val).format("H時");
          }
          return moment(val).format("MM/DD")
        }
      },
      tooltip: {
        enabled: true,
        formatter: function (val, opts) {
          return moment(val).format("H:mm")
        }
      },
    },
    tooltip: {
      shared: true,
          y: {
        formatter: function (val) {
          return val;
        }
      },
      x: {
        formatter: function (val) {
          return moment(val).format("YYYY-MM-DD HH:mm:ss");
        }
      }
    }
  }
}

export default {
  name: "BreathCharts",
  props: {
    dayCount: {
      required: true
    },dayFromTo:{
      type:Object,
      "default":() => ({from:"2021-04-27 00:00:00",to:"2021-04-28 00:00:00"})
    },
    ymin:{type:Number,default:0},
    ymax:{type:Number,default:300},
    // series: {type: Array, required: false},
    fromDate:{type:String},
    chartType:{type:String},
    chartTitle:{default:"呼吸数",type:String},
    chartHeight:{default:"200"},
    chartWidth:{default:"100%"},
    fromSeries:{type:Array},
    xTitle:{type:String},
    yTitle:{type:String}
  },
  watch:{
    fromSeries(){
      this.chartOptions = getChartOptions(this.chartTitle, this.dayCount, this.dayFromTo, this.ymin, this.ymax,this.xTitle,this.yTitle,this.series);
    },
    ymin(){
      this.chartOptions = getChartOptions(this.chartTitle, this.dayCount, this.dayFromTo, this.ymin, this.ymax,this.xTitle,this.yTitle,this.series);
    }
    ,ymax(){
      this.chartOptions = getChartOptions(this.chartTitle, this.dayCount, this.dayFromTo, this.ymin, this.ymax,this.xTitle,this.yTitle,this.series);
    }
  },
  data() {
    return {
      series: [{
        name: '呼吸数',
        data: []
      }],
      //1,7日曜
      chartOptions: getChartOptions(this.chartTitle, this.dayCount, this.dayFromTo, this.ymin, this.ymax,this.xTitle,this.yTitle),//chartOptions end
    };
  },
    methods:{
      // getSampleData() {
      //   let seriesData = [];
      //   let baseDate = moment("2021-02-19");
      //   let koksyuSuList = [0, 6, 7, 20, 13, 15, 17, 28, 19, 20, 30, 19, 10, 50, 20, 21, 11, 45, 22, 15]
      //   function getRandomData(list) {
      //     return list[Math.floor(Math.random() * list.length)];
      //   }
      //
      //   for (let i = 0; i < 60 * 24 * this.dayCount; i++) {
      //     baseDate = baseDate.add(60, 's');
      //     let innerArr = {x: baseDate.format("YYYY-MM-DD HH:mm:ss"), y: getRandomData(koksyuSuList)};
      //     seriesData.push(innerArr);
      //   }
      //
      //
      //   let newSeries = [{name: "呼吸数", data: seriesData}];
      //   this.series = newSeries;
      // },
  },
  // mounted(){
  //   this.getData();
  // }
};
</script>
<style>
/*.chart-regend{*/
/*  position: relative;*/
/*  display: inline-block;*/
/*  margin-right: 0.5em;*/
/*  white-space: nowrap;*/
/*  line-height: 0.7;*/
/*  margin-top: -20px;*/
/*  margin: 0 auto;*/
/*}*/
/*.cart-regend-item{*/
/*  !*padding:5px 3px;*!*/
/*  !*border:solid 1px rgb(180, 179, 179);*!*/
/*  vertical-align: middle;*/
/*  text-align: center;*/
/*  margin:0;*/
/*  !*position: relative;*!*/
/*  !*line-height: 15px;*!*/
/*}*/
/*#chart-wrap {*/
/*  max-width: 800px;*/
/*  position: relative;*/
/*  margin: 35px auto;*/
/*}*/

/*#chart {*/
/*  padding-bottom: 30px;*/
/*  max-width: 800px;*/
/*}*/

/*.legend {*/
/*  max-width: 800px;*/
/*  !*left: 80px;*!*/
/*  bottom: 20px;*/
/*  position: relative;*/
/*  text-align: right;*/
/*}*/
/*.card-tooltip-container{*/
/*  border: solid 1px sandybrown;*/
/*  width:250px;*/
/*  text-align: center;*/
/*  border-radius: 5%;*/
/*}*/
/*.card-tooltip-header{*/
/*  background-color: sandybrown;*/
/*  text-align: center;*/
/*  color: white;*/
/*  font-weight: bold;*/
/*}*/
/*.card-tooltip-body{*/
/*  text-align: left;*/
/*}*/

</style>
