<template>
  <textarea v-model="internalValue" @keydown="onKeyDown" style="padding-left: 5px;"></textarea>
</template>

<script>
    export default {
      props:{
        value:{ type: String, default: ""},
        max:{type: Number, default: 250},
        maxRow:{type: Number, default: 5}
      },
      data(){
        return {
          data:""
        }
      },
      methods:{
        onKeyDown(evt){
          if (this.value.length >= this.max) {
            if (evt.keyCode >= 48 && evt.keyCode <= 90) {
              evt.preventDefault()
              return
            }
          }
          if(evt.keyCode == 8){
            return
          }else if(this.data.split("\n").length > 5){
            evt.preventDefault()
            return
          }
        }
      },
      computed: {
        internalValue: {
          get() {
            return this.value
          },
          set(v) {
            this.data = v;
            console.log("this.data",this.data);
            this.$emit("input", v)
          }
        }
      }
    };
</script>
