var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("textarea", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.internalValue,
        expression: "internalValue"
      }
    ],
    staticStyle: { "padding-left": "5px" },
    domProps: { value: _vm.internalValue },
    on: {
      keydown: _vm.onKeyDown,
      input: function($event) {
        if ($event.target.composing) {
          return
        }
        _vm.internalValue = $event.target.value
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }